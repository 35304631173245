import config from 'config';

const {
    productExternalLink,
    privacyPolicyExternalLink,
    cooperCompaniesExternalLink,
} = config;

export const ContainerConstants = {
    homePage: {
        howToJoinContainerId: 'how-to-join',
        faqContainerId: 'faq',

        homePageBannerSubHeader: 'Program Period: 1 January - 31 March 2025',

        // homePageBannerSubHeaderV4: 'Program Period: 1 Oct - 31 Dec 2024',
        // homePageBannerSubHeaderV3: 'Program Period: 1 July - 30 Sept 2024',
        // homePageBannerSubHeaderV2: 'Program Period: 1 Apr - 30 Jun 2024',
        // homePageBannerSubHeaderV1: 'Program Period: 1 January - 31 December 2024',

        homePageBannerCTAText: 'Purchase CooperVision participating products and redeem an exclusive $40 GrabGifts Voucher!',

        // homePageBannerCTATextv3: 'Purchase CooperVision participating products and redeem exclusive FairPrice e-Gift Vouchers and more!',
        // homePageBannerCTATextV2: 'Purchase CooperVision participating products and redeem exclusive Klook e-Gift Cards and more!',
        // homePageBannerCTATextV1: 'Purchase CooperVision participating products and redeem exclusive e-vouchers and more!',

        howToJoinBuyDescription: '4 boxes of MyDay® range of daily disposable contact lenses OR 8 boxes of clariti® range of 1 day contact lenses in a single receipt (Limited to a maximum of 24 boxes per receipt).',

        // howToJoinBuyDescriptionV4: 'any 4 boxes of MyDay® or Biofinity® range products in a single receipt OR any 8 boxes of clariti® 1 day range products in a single receipt and redeem $40 FAIRPRICE e-gift voucher.',
        // howToJoinBuyDescriptionV3: `any 8 boxes of clariti® 1 day range products in a single receipt and get $40 Klook e-gift card
        // OR any 4 boxes of MyDay® or Biofinity® range products in a single receipt and get $40 Klook e-gift card.`,
        // howToJoinBuyDescriptionV2: `any 8 boxes of clariti™ 1 day or 4 boxes of Biofinity® range products in a single receipt and get $30 e-Decathlon voucher
        // OR any 8 boxes of MyDay® range products in a single receipt and get $40 e-Decathlon voucher.`,
        // howToJoinBuyDescriptionV1: 'any 4 boxes of MyDay® range products OR 8 boxes of clariti™ 1 day range products in a single receipt.',

        howToJoinLoginDescription: 'your purchase details & a clear receipt photo by logging in to your account or sign up if it is your first time.',
        howToJoinRedeemDescription: 'and redeem exclusive vouchers! Enjoy before it expires.',

        faqCopy: [
            {
                header: 'How do I participate?',
                body: `Step 1: Purchase any 4 boxes of MyDay® daily disposable contact lenses OR 8 boxes of clariti® range of 1 day contact lenses products in a single receipt (Limited to a maximum of 24 boxes per receipt).

                Step 2: Visit www.coopervisionSG-rewards.com and login or sign up, then submit a clear receipt photo.

                Step 3: Collect and redeem your GrabGifts Voucher!`,

                // bodyV4: `Step 1: Purchase any 4 boxes of MyDay® or Biofinity® range products in a single receipt OR any 8 boxes of clariti® 1 day range products in a single receipt and redeem $40 FAIRPRICE e-gift voucher.

                // Step 2: Visit www.coopervisionSG-rewards.com and login or sign up, then submit a clear receipt photo.

                // Step 3: Collect and redeem your FairPrice reward!`,
                // bodyV3: `Step 1: Purchase any 8 boxes of clariti® 1 day range products in a single receipt and get $40 Klook e-gift card OR any 4 boxes of MyDay® or Biofinity® range products in a single receipt and get $40 Klook e-gift card.

                // Step 2: Login / sign up an account. Submit a clear image of your purchase receipt and fill in the details requested to redeem.

                // Step 3: E-vouchers will either be emailed directly to you or awarded to your account. Redeem and enjoy your rewards!`,
                // bodyV2: `Step 1: any 8 boxes of clariti™ 1 day or 4 boxes of Biofinity® range products in a single receipt and get $30 e-Decathlon voucher
                // OR any 8 boxes of MyDay® range products in a single receipt and get $40 e-Decathlon voucher.

                // Step 2: Login / sign up an account. Submit a clear image of your purchase receipt and fill in the details requested to redeem.

                // Step 3: E-vouchers will either be emailed directly to you or awarded to your account. Redeem and enjoy your rewards!`,

                // bodyV1: `Step 1: Purchase any 4 boxes of MyDay® OR 8 boxes of clariti™ - range products in a single receipt.
                // Step 2: Login / sign up an account. Submit a clear image of your purchase receipt and fill in the details requested to redeem.
                // Step 3: E-vouchers will either be emailed directly to you or awarded to your account. Redeem and enjoy your rewards!`,

            },
            {
                header: 'How long will this Rewards Program run for?',
                body: `The CooperVision Singapore Rewards Program will run for the whole year. In every quarter there will be a new promotion and new e-vouchers to be given away, from 1st January 2025 - 31st March 2025.

                *Latest submission of entry will be by 11:59pm on 31st March 2025. Any submissions received after 31st March 2025 will not be accepted or entertained.`,

                // bodyV1: `The CooperVision Singapore Rewards Program will run for the whole year. In every quarter there will be new promotion and new e-vouchers to be rewarded, from 1st January 2024 - 31st December 2024.*

                // *Latest submission of entry will be by 11:59pm on 31st December 2024. Any submissions received after 31st December 2024 will not be accepted or entertained.`,
            },
            {
                header: 'How many times can I participate / submit?',
                body: `You can submit your receipt(s) as many times as you wish during the Promotion Period* within the Program Period. Each submitted receipt must be unique and fulfil the purchase criteria stated in every quarter.

                *There will be a new promotion beginning every quarter of the year. The latest receipt(s) submission of each quarter's promotion will be by 11:59pm on the end of the quarter. Any submissions received after the end of the quarter will not be accepted or entertained. All receipts submitted must be within their respective Promotion Period.`,

                // bodyV1: `You can submit your receipt(s) as many times as you wish during the Promotion Period* within the Program Period, as long as the submitted receipts are unique and fulfils the purchase criteria stated in every quarter.

                // *There will be new promotion in every quarter, latest receipt(s) submission of the promotion will be by 11:59pm on the end of the quarter. Any submissions received after the end of the quarter will not be accepted or entertained. All receipts submitted must be within the Promotion Period.`,
            },
            {
                header: 'How many vouchers can I collect throughout the program?',
                body: `You can collect as many vouchers as you wish during the program duration.* Vouchers will ONLY be awarded for submitted receipts which are valid and unique. *Vouchers will be awarded at the discretion of CooperVision and its agencies and representatives in its selected value and format. Recipients may not request for a change in value, denomination or a voucher in another form. Vouchers are distributed on a first come first served basis, while stocks last. CooperVision or its agencies and representatives may reject an entry at its discretion. 
                
                Quarter 1 (1 Jan - 31 Mar 2025): GrabGifts Voucher.`,

                // bodyV3: `You can collect as many vouchers as you wish during the program duration.* Vouchers will ONLY be rewarded to submitted receipts that are valid and unique.

                // *Vouchers will be rewarded as is basis. First come first served basis. While stocks last.

                // Quarter 1 (1 Jan - 31 Mar 2024): e-CapitaVouchers.
                // Quarter 2 (1 Apr - 30 Jun 2024): Decathlon e-Vouchers.
                // Quarter 3 (1 Jul - 30 Sep 2024): Klook e-Gift Cards.
                // Quarter 4 (1 Oct - 31 Dec 2024): FairPrice e-Gift Vouchers.`,

                // bodyV2: `You can collect as many vouchers as you wish during the program duration.* Vouchers will ONLY be rewarded to submitted receipts that are valid and unique.

                // *Vouchers will be rewarded as is basis. First come first served basis. While stocks last.

                // Quarter 1 (1 Jan - 31 Mar 2024): e-CapitaVouchers.
                // Quarter 2 (1 Apr - 30 Jun 2024): Decathlon e-Vouchers.
                // Quarter 3 (1 Jul - 30 Sep 2024): Klook e-Gift Cards.`,

                // bodyV1: `You can collect as many vouchers as you wish during the program duration.* Vouchers will ONLY be rewarded to submitted receipts that are valid and unique.

                // *Vouchers will be rewarded as is basis. First come first served basis. While stocks last.

                // Quarter 1 (1 Jan - 31 Mar 2024): e-CapitaVouchers.
                // Quarter 2 (1 Apr - 30 Jun 2024): Decathlon e-Vouchers.
                // Quarter 3 (1 Jul - 30 Sep 2024): Klook e-Gift Cards.
                // Quarter 4 (1 Oct - 31 Dec 2024): FairPrice e-Gift Vouchers.
                // `,

            },
            {
                header: 'What is considered as a valid receipt?',
                body: `A valid receipt must have the following criteria:

                a. Store name and / or logo printed on the receipt.
                b. Printed receipt date is within the stated Program Period and within the Promotion Period (1st January - 31st March 2025). 
                c. CooperVision's participating products purchased, and purchase quantity & value must stated clearly on the receipt. 
                d. Clear photo of full original receipt. Photocopies are not accepted.
                e. Receipts are printed officially from participating stores. 
                f. A unique invoice number (not duplicated across multiple entries).`,

                // bodyV1: `A valid receipt must have the following criterias:

                // a. Store name and / or logo printed on the receipt.
                // b. Printed receipt date is within the stated Program Period and within the Promotion Period (1st January – 31st December 2024).
                // c. CooperVision participating products purchased, and purchase quantity & value stated clearly.
                // d. Clear photo of full original receipt. Photocopies are not accepted.
                // e. Receipts are printed officially from participating stores.
                // f. Invoice number.`,
            },
            {
                header: 'How can I redeem my GrabGifts Voucher?',

                // headerV1: 'How can I redeem my e-voucher?',

                body: `To redeem your voucher, ensure that you have the latest version of the Grab App installed on your phone, as it 
                cannot be redeemed via a computer or website.

                Upon a successful submission in www.coopervisionSG-rewards.com, You will receive an acknowledgement email 
                which will show your application status.

                To redeem your $40 GrabGifts Voucher: 

                1. Once you've received an email notification (which shows as “approved' status), Click on the “Visit our 
                website” button, which will direct you to the www.coopervisionSG-rewards.com.
                2. Tap the 3 dots icon at the top right corner > Your Account > Voucher > Collections, which will direct you to 
                the Vouchers collections page.
                3. Click on the Voucher > Read T&C > Tap the “Redeem Now” Button.
                4. Copy the GrabGifts Voucher URL to claim it in the Grab App, which will redirect you to the Grab App (*only 
                click the “Redeem Complete” button after you've successfully redeemed your voucher in Grab App).
                5. A pop-up message will appear with the message, "Thank you! Here's a $40.00 gift voucher.“
                6. 6. Tap the "Redeem Your Gift" button, and the $40 will be automatically credited to your Grab account.

                The system will detect the first use of the $40 GrabGifts Voucher. When you select a category such as Transport, 
                Food, or Mart, the voucher will only be applicable within the selected category.

                The GrabGifts Voucher can be used multiple times until the balance is depleted or it expires. Only the total 
                transaction amount will be deducted from the GrabGifts Voucher, and you cannot choose the specific value to be 
                deducted.

                If the balance is below the transaction amount, the GrabGifts Voucher can be used and the remaining balance 
                can be paid via other payment methods.`,

                // bodyV1: 'Login to your account, you can find your e-voucher collections under “Voucher(s) - Collections”. You can redeem your vouchers any time even after the program ends, AND before the voucher\'s expiration date.',
            },
            {
                header: 'Where can I use my GrabGifts Voucher?',

                // headerV1: 'Where can I use my e-voucher?',

                body: 'Each voucher and its use is governed by its own Terms & Conditions as determined by Grab. You may find out more details of the voucher in your e-voucher collection.',

                // bodyV1: 'Each voucher is governed by it\'s own Terms & Conditions. You may find out more details of the voucher in your e-voucher collection.',
            },
            {
                header: 'Can I exchange my redeemed vouchers for other items or similar value?',
                body: 'The vouchers are rewarded on an as-is basis and are non-transferable nor exchangeable for any other voucher of equivalent value.',
            },
            {
                header: 'Who should I contact if I have further enquiry?',
                body: 'Should you have any further enquiries about this program, please email us at info@sg.coopervision.com.',
            },
        ],
    },
    authPage: {
        header: 'CooperVision SG Rewards Program',
        subheader: 'Program Period: 1 January - 31 March 2025',
        signUpTerms: 'By creating an account, I agree to this website\'s Terms And Conditions and Privacy Policy.',
    },
    profilePage: {
        header: 'Welcome to CooperVision SG Rewards Program',
        subheader: 'Program Period: 1 January - 31 March 2025',

        submissionCompleteHeader: 'Submission Complete!',
        submissionCompleteBody: 'Your submission has been received and we will respond to you within 3 - 7 working days. Your voucher(s) will be rewarded to you in your voucher(s) collection if your entry is valid.',

        changePasswordSuccessHeader: 'Password Changed!',
        changePasswordSuccessBody: 'Your password have been changed successfully.',
    },
    termsAndConditionsPage: {
        termsAndConditionsCopy: [
            {
                header: 'The CooperVision Singapore Rewards Program is organised by CooperVision Singapore in collaboration with our eye care professionals and will run from 1 January 2025, 00:00am to 31 March 2025, 11:59pm',

                // headerV1: 'The CooperVision Singapore Rewards Program is organised by CooperVision Singapore in collaboration with Eye Care Professionals and will run from 1 January 2024, 00:00am to 31 December 2024, 11:59pm.',
            },
            {
                header: 'This Rewards Program is open to all legal residents of Singapore aged 18 years (as of the commencement of this program), excluding employees and immediate families of the Organiser, inclusive of affiliated and/or related companies, distributors, advertising, and promotion agencies.',
            },
            {
                header: 'Rewards Program mechanics are as follows:',
                steps: [
                    {
                        item: 'Purchase any 4 boxes of MyDay® daily disposable contact lenses OR 8 boxes of clariti® range of 1 day contact lenses products in a single receipt (Limited to a maximum of 24 boxes per receipt).',
                    },
                    {
                        item: 'Visit www.coopervisionSG-rewards.com and login or sign up, then submit a clear receipt photo.',
                    },
                    {
                        item: 'Collect and redeem your GrabGifts Voucher.',
                    },
                ],

                // stepsV4: [
                //     {
                //         item: 'Purchase any 4 boxes of MyDay® or Biofinity® range products in a single receipt OR any 8 boxes of clariti® 1 day range products in a single receipt and redeem $40 FAIRPRICE e-gift voucher.',
                //     },
                //     {
                //         item: 'Visit www.coopervisionSG-rewards.com and login or sign up, then submit a clear receipt photo.',
                //     },
                //     {
                //         item: 'Collect and redeem your FairPrice reward!',
                //     },
                // ],
                // stepsV3: [
                //     {
                //         item: 'Purchase any 8 boxes of clariti® 1 day range products in a single receipt. OR Purchase any 4 boxes of MyDay® or Biofinity® range products in a single receipt.',
                //     },
                //     {
                //         item: 'Visit www.coopervisionSG-rewards.com and login or sign up, then submit a clear receipt photo.',
                //     },
                //     {
                //         item: 'Collect and redeem exclusive e-vouchers.',
                //     },
                // ],
                // stepsV2: [
                //     {
                //         item: 'Purchase any 8 boxes of MyDay® range products OR 8 boxes of clariti™ 1 day range products OR 4 boxes of Biofinity® range products in a single receipt.',
                //     },
                //     {
                //         item: 'Visit www.coopervisionSG-rewards.com and login or sign up, then submit a clear receipt photo.',
                //     },
                //     {
                //         item: 'Collect and redeem exclusive e-vouchers.',
                //     },
                // ],
                // stepsV1: [
                //     {
                //         item: 'Purchase any 4 boxes of MyDay® range products in a single receipt. OR Purchase any 8 boxes of clariti™ 1 day range products in a single receipt.',
                //     },
                //     {
                //         item: 'Visit www.coopervisionSG-rewards.com and login or sign up, then submit a clear receipt photo.',
                //     },
                //     {
                //         item: 'Collect and redeem exclusive e-vouchers.',
                //     },
                // ],
            },
            {
                header: 'The e-vouchers consist of:',
                alphabetList: [
                    {
                        item: 'Quarter 1 (1 Jan - 31 Mar 2025) - GrabGifts Vouchers',
                    },
                ],

                // alphabetListV3: [
                //     {
                //         item: 'Quarter 1 (1 Jan - 31 Mar 2024) - e-CapitaVouchers.',
                //     },
                //     {
                //         item: 'Quarter 2 (1 Apr - 30 Jun 2024) - Decathlon e-Vouchers.',
                //     },
                //     {
                //         item: 'Quarter 3 (1 Jul - 30 Sep 2024) - KLOOK e-Gift Card.',
                //     },
                //     {
                //         item: 'Quarter 4 (1 Oct - 31 Dec 2024) - FairPrice e-Gift Vouchers.',
                //     },
                // ],
                // alphabetListV2: [
                //     {
                //         item: 'Quarter 1 (1 Jan - 31 Mar 2024) - e-CapitaVouchers.',
                //     },
                //     {
                //         item: `Quarter 2 (1 Apr - 30 Jun 2024) - Decathlon e-Vouchers:
                // i. $30 Decathlon e-Voucher for every purchase of 8 boxes of clariti™ 1 day range products.
                // ii. $30 Decathlon e-Voucher for every purchase of 4 boxes of Biofinity® range products.
                // iii. $40 Decathlon e-Voucher for every purchase of 8 boxes of MyDay® range products.
                //                         `,
                //     },
                // ],
                // alphabetListV1: [
                //     {
                //         item: 'Quarter 1 (1 Jan - 31 Mar 2024) - e-CapitaVouchers',
                //     },
                // ],
            },
            {
                header: 'All entries received after the promotion period, the e-vouchers / e-gift cards will be rewarded as stated on the promotion period.',
            },
            {
                header: 'Each participant is eligible to submit multiple entries within the Program Period as long as each entry is accompanied by a unique receipt. Subsequent submissions with the same receipt photos shall  not qualify. Entries with unclear receipts shall not qualify. Only entry submissions  made on the CooperVision Singapore Rewards Program microsite shall  qualify. All entries received after the Program Period will not be entertained.',
            },
            {
                header: 'Proof of entry submission is not proof of receipt. The Organiser shall not be liable for any delay, lost, damaged and/or non-receipt of submissions, any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, or any problems or technical malfunction of telecommunications network or lines, computer online systems, servers or providers, computer equipment, software, failure of e-mail or participants\' accounts of technical problems or traffic congestion on the Internet.',
            },
            {
                header: 'If the participant fails to provide the details above within the stipulated duration, the Organiser reserves the right to disqualify their claim or award it to the next eligible participant.',
            },
            {
                header: 'The GrabGifts Vouchers are awarded as-is on a first-come-first-served basis, are non-transferable and  cannot be exchanged for cash or other items of similar value. The Organiser reserves the right and absolute discretion to substitute the vouchers with another of similar value at any time without prior notice or provide any reason.',
                // headerV2: 'The FairPrice E-Gift Vouchers are awarded as is and it cannot be exchanged for other items of similar value. The E-Gift Vouchers are not transferable or exchangeable for cash. The Organiser reserves the right and absolute discretion to substitute the vouchers with another of similar value at any time without prior notice or provide any reason.',
                // headerV1: 'The vouchers are awarded as is and it cannot be exchanged for other items of similar value. The prizes are not transferable or exchangeable for cash. The Organiser reserves the right and absolute discretion to substitute the vouchers with another of similar value at any time without prior notice or provide any reason.',
            },
            {
                header: 'The e-Vouchers are delivered based on the details provided by the Claimants that were submitted. Claimants are responsible for providing correct and valid details. They shall assume full responsibility and liability in case of damage, loss, or theft. Claimants who require representatives to claim the Voucher(s) on their behalf must ensure that their representatives provide authorization letters issued by the Claimants together with the original receipts and copies of the Claimants\' details. Failure to comply with the foregoing shall result in forfeiture of the Vouchers. No late or expired voucher redemptions shall be entertained for any reason whatsoever.',
                // headerV1: 'The Vouchers are delivered based on the details provided by the Claimants that were submitted. Claimants are responsible for providing correct and valid details. They shall assume full responsibility and liability in case of damage, loss, or theft. Claimants who require representatives to claim the Voucher(s) on their behalf must ensure that their representatives provide authorization letters issued by the Claimants together with the original receipts and copies of the Claimants\' details. Failure to comply with the foregoing shall result in forfeiture of the Vouchers. No late or expired Vouchers redemption shall be entertained for any reason whatsoever.',
            },
            {
                header: 'Liability & Responsibility:',
                alphabetList: [
                    {
                        item: 'The Organiser shall not be liable for any incomplete, lost, late, damaged, illegible or misdirected entries for any reason whatsoever or problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, technical problems or traffic congestion on the internet or at any website, or any combination thereof (including but not limited to) any injury or damage to the Participants or their personal computer or telecommunication device related to or resulting from participation in the Program and it\'s mechanics.',
                    },
                    {
                        item: 'To the extent permitted by law, each Participant and any person acting on his/her behalf agree that the Organiser shall not be responsible and / or liable for any loss, injury, death, claim or damage suffered by any person arising out of or in connection with the Program and / or use of the Prizes, and shall release completely and indemnify the Organiser from any or all claims losses, damages, costs or expenses incurred in connection therewith.',
                    },
                    {
                        item: 'The Participants shall indemnify and keep the Organiser and its officers, agents and employees ("the Indemnified Parties") fully indemnified against any and all loss, damage, claims and costs (including legal costs on a full indemnity basis) suffered and/or incurred by any of the Indemnified Parties as a result of the Participants\' breach of any of these Terms and Conditions.',
                    },
                    {
                        item: 'The Organiser assumes no responsibility or liability for the acts or defaults of merchants or third parties, any non-delivery, non-performance or defects in any Prize given out in the course of the Program. The Organiser makes no representation or warranty whatsoever in respect of any Prize, whether as to the satisfactory quality or performance or otherwise whatsoever. For the avoidance of doubt, the Organiser is not an agent of any merchant or vice versa. Any dispute should be resolved directly with the relevant merchant.',
                    },
                ],
            },
            {
                header: 'By participating in the Program, the Participants give consent and absolute right to the Organiser to use, including but not limited to, their names, photographs, video clips and their Prizes as materials for the purposes of advertising, marketing, trade and/or publicity, without any prior notice. The Participants shall not be entitled to claim ownership and/or other forms of compensation for the use of the materials by the Organiser.',
            },
            {
                header: 'The Organiser reserves the right to modify, suspend and/or cancel the Program at any time without the need to provide any notice or assign any reason whatsoever. The Organiser reserves the right and sole discretion, to disqualify any Participant that is found or suspected of tampering with the Program submission process or the operation of the Program and the Organiser reserves the right to pursue legal action against any individual it believes has undertaken fraudulent activities or other activities to the detriment of the Program.',
            },
            {
                header: 'By participating in this program, participants agree to be bound by these Terms and Conditions.',
            },
            {
                header: 'The Program Terms & Conditions and the Program mechanics may be amended from time to time and shall prevail over any provisions or representations contained in any other promotional material or advertising of the Program.',
            },
            {
                header: 'Participants are advised to visit the Organiser\'s website from time to time to refer to the latest Program Terms & Conditions.',
            },
            {
                header: 'The Program Terms and Conditions shall be governed by the laws of Singapore and subject to the exclusive jurisdiction of the Singapore courts.',
            },
            {
                header: 'CooperVision Singapore reserves the right to add or amend these Terms and Conditions without any prior notice at any time and all decisions made will be final.',
            },
        ],
        privacyPolicyCopy: 'Visit here for more information on CooperVision Singapore Privacy Policy.',
    },
};

export const NavigationConstants = {
    homePage: '/',
    loginPage: '/login',
    productsPage: productExternalLink,
    profilePage: '/profile',
    termsAndConditionsPage: '/terms-and-conditions',
    forgotPasswordPage: '/forgot-password',
    verifyOtpPage: '/verify-otp',
    resetPasswordPage: '/reset-password',
    privacyPolicyPage: privacyPolicyExternalLink,
    cooperCompaniesPage: cooperCompaniesExternalLink,
};

export const FooterConstants = {
    cooperVisionSGSiteUrl: 'info@sg.coopervision.com',
    cooperVisionAsiaPacificOfficeAddress: '1 Temasek Avenue, #39-01 Millenia Tower, Singapore 039192',
    // cooperVisionAsiaPacificOfficeAddressV1: '8 Temasek Boulevard #19-03, Suntec Tower 3, Singapore 038988',
};
