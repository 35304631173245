import React from 'react';

import CustomAccordion from 'components/CustomAccordion';
import { ContainerConstants } from 'lib/Constants';

import FAQSectionStyles from './_FAQSectionStyles.module.scss';

const FAQSection = (): JSX.Element => {
    const {
        homePage: {
            faqContainerId,
            faqCopy,
        },
    } = ContainerConstants;

    const massagedFaqCopy = faqCopy.map(copy => {
        if (copy.header === 'How can I redeem my GrabGifts Voucher?') {
            return {
                header: copy.header,
                body: (
                    <p className={FAQSectionStyles['faq-body']}>
                        To redeem your voucher, ensure that you have the latest version of the
                        {' '}
                        <span>Grab App</span>
                        {' '}
                        installed on your
                        {' '}
                        <span>phone</span>
                        , as it cannot be redeemed via a computer or website.

                        <br />
                        <br />

                        Upon a successful submission in www.coopervisionSG-rewards.com, You will receive an acknowledgement
                        {' '}
                        <span>email which will show your application status.</span>

                        <br />
                        <br />

                        <span>To redeem your $40 GrabGifts Voucher: </span>

                        <br />
                        <br />

                        1. Once you&apos;ve received an
                        {' '}
                        <span>email notification</span>
                        {' '}
                        (which shows as
                        {' '}
                        <span>&quot;approved&quot;</span>
                        {' '}
                        status), click on the
                        {' '}
                        <span>&quot;Visit our website&quot;</span>
                        {' '}
                        button, which will direct you to
                        {' '}
                        <a href='https://www.coopervisionSG-rewards.com'>www.coopervisionSG-rewards.com</a>
                        .

                        <br />

                        2. Tap the
                        {' '}
                        <span>3 dots</span>
                        {' '}
                        icon at the top right corner &gt; Your Account &gt; Voucher &gt; Collections, which will direct you to
                        the Vouchers collections page.

                        <br />

                        3. Click on the Voucher &gt; Read T&C &gt; Tap the
                        {' '}
                        <span>&quot;Redeem Now&quot;</span>
                        {' '}
                        Button.

                        <br />

                        4. Copy the
                        {' '}
                        <span>GrabGifts Voucher URL</span>
                        {' '}
                        to claim it in the Grab App, which will redirect you to the Grab App (*only click the
                        {' '}
                        <span>&quot;Redeem Complete&quot;</span>
                        {' '}
                        button after you&apos;ve successfully redeemed your voucher in Grab App).

                        <br />

                        5. A pop-up message will appear with the message, &quot;Thank you! Here&apos;s a $40.00 gift voucher.&quot;

                        <br />

                        6. Tap the
                        {' '}
                        <span>&quot;Redeem Your Gift&quot;</span>
                        {' '}
                        button, and the $40 will be automatically credited to your Grab account.

                        <br />
                        <br />

                        The system will detect the first use of the $40 GrabGifts Voucher. When you select a category such as Transport,
                        Food, or Mart, the voucher will only be applicable within the selected category.

                        <br />
                        <br />

                        The GrabGifts Voucher can be used multiple times until the balance is depleted or it expires. Only the total
                        transaction amount will be deducted from the GrabGifts Voucher, and you cannot choose the specific value to be
                        deducted.

                        <br />
                        <br />

                        If the balance is below the transaction amount, the GrabGifts Voucher can be used and the remaining balance
                        can be paid via other payment methods.
                    </p>
                ),
            };
        }

        return copy;
    });

    return (
        <div
            id={faqContainerId}
            className={FAQSectionStyles.container}
        >
            <h2>
                FAQ
            </h2>

            <CustomAccordion
                data={massagedFaqCopy}
            />
        </div>
    );
};

export default FAQSection;
